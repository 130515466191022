import "./multipleChoiceQuestion.css"
import { H6 } from "@bootstrap-styled/v4/";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { AnswerOptions } from "./AnswerOptions";
import { Standards } from "./Standards";
import { PreviewMultipleChoiceQuestionModal } from "./PreviewMultipleChoiceQuestionModal";
import { validSolution } from "utils";

const TextInput = (props) => {
  const { label, type, value, onChange, labelClassName, inputClassName, divclassName="challenge-prop horizontal",  ...rest } = props;
  return (
    <div className={divclassName}>
      <H6 className={labelClassName}>{label}</H6>
      <input
        className={inputClassName || "full-width round-box"}
        type={type}
        value={value}
        onChange={onChange}
        {...rest}
      />
    </div>
  );
};

const SelectInput = (props) => {
  const { label, id, value, options, onChange } = props;
  return (
    <div className="challenge-prop horizontal">
      <H6 className="header-margin-right">{label}</H6>
      <select id={id} name={id} value={value} onChange={onChange}>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export const MultipleChoiceQuestion = ({ challenge, index, showHeader }) => {
  const [prompt, setPrompt] = useState(challenge?.prompt);
  const [spokenPrompt, setSpokenPrompt] = useState(challenge?.spoken_prompt);
  const [difficulty, setDifficulty] = useState(challenge?.difficulty);
  const [solutionIndex, setSolutionIndex] = useState(challenge?.solution_index);
  const [answerOptions, setAnswerOptions] = useState(challenge?.answer_options);
  const [randomizeOptions, setRandomizeOptions] = useState(challenge?.randomize_options);
  const [promptImageUrl, setPromptImageUrl] = useState(challenge?.prompt_image_url);
  const [standards, setStandards] = useState(challenge?.standards ?? []);
  const [comments, setComments] = useState(challenge?.comments);
  const [renderType, setRenderType] = useState(challenge?.render_type);
  const [status, setStatus] = useState(challenge?.status ?? "active");
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [solution, setSolution] = useState(challenge?.solution);
  const mcMode = !!challenge?.answer_options;

  const renderTypeOptions = [
    { value: "fraction_legacy", label: "Fraction Legacy" },
    { value: "math", label: "Math" }
  ];

  const statusOptions = [ { value: "active", label: "Active" }, { value: "inactive", label: "Inactive" } ];

  const buildChallengeRequest = () => {
    const request = {};
    request.id = challenge.id;
    request.difficulty = difficulty;
    request.standards = standards;
    request.comments = comments;
    request.status = status;
    request.prompt = prompt;
    request.spoken_prompt = spokenPrompt;
    request.randomize_options = randomizeOptions === true;
    request.prompt_image_url = promptImageUrl;
    request.render_type = renderType;
    request.solution_index = solutionIndex;
    request.answer_options = answerOptions;
    request.solution = solution;
    return request;
  };

  const handlePreview = () => setIsPreviewModalOpen(true);
  const handlePrompt = (e) => setPrompt(e.target.value);
  const handleSpokenPrompt = (e) => setSpokenPrompt(e.target.value);
  const handleDifficulty = (e) => setDifficulty(e.target.value);
  const handleRandomizeOptions = (e) => setRandomizeOptions(e.target.checked);
  const handlePromptImageUrl = (e) => setPromptImageUrl(e.target.value);
  const handleComments = (e) => setComments(e.target.value);
  const handleRenderType = (e) => setRenderType(e.target.value);
  const handleStatus = (e) => setStatus(e.target.value);
  const handleSolution = (e) => {
    const value = e.target.value;
    if (validSolution(value)) {
      setSolution(value);
    }
  }

  const addAnswerOption = () => {
    const newAnswerOptions = JSON.parse(JSON.stringify(answerOptions));
    newAnswerOptions.push("");
    setAnswerOptions(newAnswerOptions);
  }

  const addStandard = () => {
    const newStandards = JSON.parse(JSON.stringify(standards));
    newStandards.push("");
    setStandards(newStandards);
  }

  const answerOptionsComp = (
    <div className="challenge-prop half-width">
      <AnswerOptions answerOptions={answerOptions}
                      setAnswerOptions={setAnswerOptions}
                      solutionIndex={solutionIndex}
                      setSolutionIndex={setSolutionIndex}
      />
      <button style={{ borderRadius: "100px", borderWidth: "1px", marginLeft: "18px" }}
              onClick={addAnswerOption}>
        <FontAwesomeIcon icon={faPlus} />
        &nbsp;Add answer option
      </button>
    </div>
  );

  return (
    <div className="multiple-choice-question">
      <Accordion.Item eventKey={index} key={index}>
        {showHeader && (
          <Accordion.Header>
            ID: {challenge.id}&nbsp;&nbsp;&nbsp;
            Prompt: {prompt}&nbsp;&nbsp;&nbsp;
            Updated At: {challenge.updated_at}&nbsp;&nbsp;&nbsp;
            Updated By: {challenge.updated_by}&nbsp;&nbsp;&nbsp;
            Status: {status.toUpperCase()}&nbsp;&nbsp;&nbsp;
          </Accordion.Header>
        )}
        <Accordion.Body>
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <div>
              <TextInput
                label="Prompt"
                divclassName="challenge-prop"
                type="text"
                value={prompt}
                onChange={handlePrompt}
              />
              <TextInput
                label="Spoken Prompt"
                divclassName="challenge-prop"
                type="text"
                value={spokenPrompt}
                onChange={handleSpokenPrompt}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {mcMode ? (
                answerOptionsComp
              ) : (
                <TextInput
                  label="Solution"
                  divclassName="challenge-prop"
                  type="text"
                  value={solution}
                  onChange={handleSolution}
                />
              )}
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div className="challenge-prop">
                  <Standards standards={standards} setStandards={setStandards} />
                  <button className="round-box" onClick={addStandard}>
                    <FontAwesomeIcon icon={faPlus} />
                    &nbsp;Add standard
                  </button>
                </div>
              </div>
              <div
                style={{
                  marginLeft: "15px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <TextInput
                  label="Difficulty"
                  labelClassName="header-margin-right"
                  inputClassName="input-number round-box"
                  type="number"
                  value={difficulty}
                  onChange={handleDifficulty}
                  min="1"
                  max="5"
                />
  
                {mcMode && (
                  <div className="challenge-prop horizontal">
                    <H6 className="header-margin-right">Randomize Options</H6>
                    <input
                      type="checkbox"
                      checked={randomizeOptions === true}
                      onClick={handleRandomizeOptions}
                    />
                  </div>
                )}
  
                <SelectInput
                  label="Render Type"
                  id="renderType"
                  value={renderType}
                  onChange={handleRenderType}
                  options={renderTypeOptions}
                />
  
                <SelectInput
                  label="Status"
                  id="status"
                  value={status}
                  onChange={handleStatus}
                  options={statusOptions}
                />
              </div>
            </div>
            <TextInput divclassName= {"challenge-prop horizontal"} labelClassName = {"image-url-width"} label="Prompt Image URL" type="url" value={promptImageUrl} onChange={handlePromptImageUrl} />
            <TextInput divclassName= {"challenge-prop horizontal"} labelClassName = {"header-margin-right"} label="Comments" type="text" value={comments} onChange={handleComments} />
          
          </div>
          <div className="preview-button-div">
            <button className="preview-button round-box" onClick={handlePreview}>
              <FontAwesomeIcon icon={faSearch} />
              &nbsp;Preview challenge in rendering mode and save changes
            </button>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <PreviewMultipleChoiceQuestionModal
        challenge={buildChallengeRequest()}
        isModalOpen={isPreviewModalOpen}
        setIsModalOpen={setIsPreviewModalOpen}
      />
    </div>
  );
  
}