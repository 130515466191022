import "./facilitationAIPrompConsole.css";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Button, Form } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import * as Sentry from "@sentry/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
  colors,
  constants,
  divider,
  generateRoutePath,
  getActivityName,
  getDate,
  getDuration,
  getLength,
  getSessionItem
} from "utils";
import { useAuthUserData } from "hooks/useAuthUserData/useAuthUserData";
import { request } from "hooks/useOkoApi/useOkoApi";
import IconInfo from "assets/Icon-Info.svg";
import { LLMEvent } from "./LLMEvent";

export const FacilitationAIPromptConsole = () => {
  const { authUserData, loading } = useAuthUserData();
  const [sessionFormData, setSessionFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [sessionLLMData, setSessionLLMData] = useState();
  const history = useHistory();
  const llmEventRefs = useRef([]);

  if (loading) return <></>;
  if (authUserData.isAnonymous) history.push(generateRoutePath(constants.ADMIN_LOGIN_ROUTE_NAME));

  const adjustTextAreas = () => {
    llmEventRefs.current.forEach(ref => {
      if (ref) {
        ref.adjustTextAreas();
      }
    });
  };

  const handleGetSessionLLMEvents = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setSessionLLMData(null)
    await request(`session_summarization/${sessionFormData.session_id}/llm_events`, {
      method: "GET",
    }).then((response) => {
      setSessionLLMData(response);
      setIsLoading(false);
    }).catch((e) => {
      console.log(e);
      setIsLoading(false);
      Sentry.captureException(e);
      const errors = {};
      if (e.detail && Array.isArray(e.detail)) {
        e.detail.forEach((error) => {
          errors[error.loc.pop()] = error.msg;
        });
      } else {
        errors["common"] = e?.detail || e?.message || "An unknown error occurred.";
      }
      setError(errors);
    });
  };

  const getPrefixes = (prefixes) => {
    let result = prefixes.map((i) => i);
    if (result.length > 0) {
      return result.join(", ");
    }
    return "N/A";
  };

  const handleChangeSessionIdForm = (e) => {
    const { name, value } = e.target;
    setSessionFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const alertStyle = {
    padding: "8px",
    marginBottom: 0,
    marginTop: "16px"
  };

  return (
    <div style={{
      display: "flex",
      backgroundColor: colors.milkGlass200,
      flexDirection: "column",
      gap: "24px" }}>
      <div style={{
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: "12px",
        boxShadow: "0px 1px 2px 0px rgba(14, 26, 49, 0.05)" }}>
        <div>
          <Alert style={{ marginBottom: 0 }}
                 variant="">
            <div style={{ display: "flex", gap: "16px" }}>
              <div style={{
                alignItems: "center",
                display: "flex",
                minHeight: "36px",
                gap: "12px",
                flex: "1 0 0",
                paddingRight: "28px" }}>
                <img src={IconInfo} />
                {constants.FACILITATION_AI_PROMPT_CONSOLE_MESSAGE}
              </div>
            </div>
          </Alert>
        </div>
      </div>
      <div style={{
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: "12px",
        boxShadow: "0px 1px 2px 0px rgba(14, 26, 49, 0.05)",
        padding: "32px" }}>
        <Form onSubmit={handleGetSessionLLMEvents}>
          <div style={{ display: "flex", flexDirection: "column" , gap: "32px" }}>
            <div style={{ display: "flex", flexDirection: "row" , gap: "32px", justifyContent: "space-between" }}>
              <div style={{ display: "flex", flexDirection: "column" , gap: "24px", width: "100%" }}>
                <Form.Group>
                  <Form.Label style={{ fontWeight: "bold" }}>Session Id</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter session id"
                    value={sessionFormData.session_id}
                    name="session_id"
                    onChange={handleChangeSessionIdForm}
                  />
                  {error?.session_id && (
                    <Alert style={alertStyle} variant="danger">{error.session_id}</Alert>
                  )}
                </Form.Group>
              </div>
              <Button style={{
                alignSelf: "end",
                backgroundColor: colors.nightlyWoods500,
                border: "none",
                borderRadius: "6px",
                fontSize: "16px",
                height: "40px",
                padding: "0 16px",
                width: "100%" }}
                      disabled={isLoading}
                      type="submit">
                Submit
              </Button>
            </div>
            {error?.common && (
              <Alert style={alertStyle} variant="danger">{error.common}</Alert>
            )}
          </div>
        </Form>
      </div>
      {sessionLLMData && <div style={{
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: "12px",
        boxShadow: "0px 1px 2px 0px rgba(14, 26, 49, 0.05)",
        padding: "32px" }}>
        <div style={{ display: "flex", flexDirection: "row", gap: "24px", marginBottom: "32px" }}>
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <p style={{ fontSize: "18px", fontWeight: 700, lineHeight: "26px" }}>{getActivityName(sessionLLMData.session.activity_type)}&nbsp;Activity</p>
            <div style={{ display: "flex", flexDirection: "row", fontSize: "14px", gap: "8px" }}>
              <p>{getDate(sessionLLMData.session.started_at)}</p>
              <p>{getLength(sessionLLMData.session.started_at, sessionLLMData.session.ended_at)}</p>
            </div>
          </div>
          {divider}
          {getSessionItem("Duration", getDuration(sessionLLMData.session.started_at, sessionLLMData.session.ended_at))}
          {divider}
          {getSessionItem("Group Code", sessionLLMData.group_code.toUpperCase())}
          {divider}
          {getSessionItem("Core Standards", getPrefixes(sessionLLMData.session.standards))}
        </div>
        <Accordion className="facilitation-ai-prompt-console" onClick={adjustTextAreas}>
          <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            {sessionLLMData.llm_events.map((item, index) => (
              <LLMEvent
                index={index}
                key={index}
                llm_response_ts={item.llm_response_ts}
                llm_response={item.llm_response}
                llm_request_ts={item.llm_request_ts}
                llm_request={item.llm_request}
                ref={(el) => llmEventRefs.current[index] = el}>
              </LLMEvent>
            ))}
          </div>
        </Accordion>
      </div>}
      {isLoading && <div style={{ alignSelf: "center", position: "fixed", top: "50%" }}>
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          size="5x"
        />
      </div>}
    </div>
  );
};
