import EventEmitter from "events";
import { isMobile } from "utils";
import { useCallback, useEffect, useRef, useState } from "react";
import { LocalAudioTrack, Room, RoomEvent, Track } from "livekit-client";
import { useAppStateContext } from "hooks/useAppState/useAppState";
import { isKrispNoiseFilterSupported } from "@livekit/krisp-noise-filter";

export default function useRoom(localTracks, onError, connectionOptions, roomOptions) {
  const [room, setRoom] = useState(new EventEmitter());
  const [isConnecting, setIsConnecting] = useState(false);
  const { isRecordSessions, isSpectator } = useAppStateContext();
  const optionsRef = useRef(connectionOptions);
  useEffect(() => {
    // This allows the connect function to always access the most recent version of the connectionOptions object.
    // This allows us to reliably use the connect function at any time.
    optionsRef.current = connectionOptions;
  }, [connectionOptions]);

  const connect = useCallback(
    (url, token) => {
      setIsConnecting(true);
      const room = new Room(roomOptions);
      return room.connect(url, token, {
        ...optionsRef.current,
      }).then(
        async () => {
          // Krisp noise filter
          // Dynamic import in order to only load the required resources once we actually enable the plugin
          const { KrispNoiseFilter } = await import('@livekit/krisp-noise-filter');
          room.on(RoomEvent.LocalTrackPublished, async (trackPublication) => {
            if (
              trackPublication.source === Track.Source.Microphone &&
              trackPublication.track instanceof LocalAudioTrack
            ) {
              if (!isKrispNoiseFilterSupported()) {
                console.warn('enhanced noise filter is currently not supported on this browser');
                return;
              }
              // once instantiated the filter will begin initializing and will download additional resources
              const krispProcessor = KrispNoiseFilter();
              await trackPublication.track.setProcessor(krispProcessor);
              await krispProcessor.setEnabled(true)
              console.log('Krisp enhanced noise filter enabled:', krispProcessor.isEnabled());
            }
          });
          if (isRecordSessions && !isSpectator) {
            room.localParticipant.setScreenShareEnabled(
              true,
              {
                selfBrowserSurface: "include",
                surfaceSwitching: "exclude",
                preferCurrentTab: true,
              }
            );
          }
          localTracks.forEach(async (track) => {
            await room.localParticipant.publishTrack(track);
          });
          setRoom(room);
          const disconnect = () => room.disconnect();
          room.once(RoomEvent.Disconnected, () => {
            // Reset the room only after all other `disconnected` listeners have been called.
            setTimeout(() => setRoom(new EventEmitter()));
            window.removeEventListener("beforeunload", disconnect);
            if (isMobile) {
              window.removeEventListener("pagehide", disconnect);
            }
          });
          setIsConnecting(false);
          // Add a listener to disconnect from the room when a user closes their browser
          window.addEventListener("beforeunload", disconnect);
          if (isMobile) {
            // Add a listener to disconnect from the room when a mobile user closes their browser
            window.addEventListener("pagehide", disconnect);
          }
        },
        (error) => {
          onError(error);
          setIsConnecting(false);
        }
      );
    },
    [localTracks, onError]
  );

  return { room, isConnecting, connect };
}
