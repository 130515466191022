import "./multipleChoiceActivity.css"
import React from 'react';
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Fullscreen } from "components/shared/Fullscreen/Fullscreen";
import { useActivity } from "hooks/useActivity/useActivity";
import { useOkoSessionContext } from "hooks/useOkoSession/useOkoSession";
import { useDataTrack } from "hooks/livekit/useDataTrack/useDataTrack";
import { useLocalParticipantUserId } from "hooks/useLocalParticipant/useLocalParticipant.js";
import { VideosBar } from "components/pages/Session/shared/VideosBar/VideosBar";
import useLocalVideoToggle from "hooks/livekit/useLocalVideoToggle/useLocalVideoToggle";
import { MultipleChoiceActivityRender } from "./MultipleChoiceActivityRender";

export const MultipleChoiceActivity = () => {
  const {
    consensusSession: { multipleChoiceQuestionResponse, openResponseQuestionResponse },
  } = useOkoSessionContext();
  const { activity } = useActivity();
  const [timerValue, setTimerValue] = useState(null);
  const [timerTimeRemaining, setTimerTimeRemaining] = useState(null);
  const localParticipantUserId = useLocalParticipantUserId();
  const { update: updateActivity } = useActivity();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [disableOptions, setDisableOptions] = useState(false);
  const [hideQuestion, setHideQuestion] = useState(true);
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const [isVideoToggled, setIsVideoToggled] = useState(false);
  const [isChallengeSolved, setIsChallengeSolved] = useState(false);
  const [challenge, setChallenge] = useState(null);
  const [isShowRequestMoreTimeButton, setIsShowRequestMoreTimeButton] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  useDataTrack((e) => {
    if (e.event_type !== "time_warning") return;
    if (!e?.data?.oko_user_ids?.includes(localParticipantUserId)) return;
    setTimerValue(e.data.time_remaining_seconds);
    if (e?.data?.is_more_time_request) {
      if (timeoutId) clearTimeout(timeoutId);
      setTimerTimeRemaining(null);
      setIsShowRequestMoreTimeButton(false);
      return;
    }
    // The timer widget appears only after the speech expression ends.
    // In order to reduce complexity and save on introducing another state variable,
    // the "more time" button will be displayed after 15 seconds after the event is received,
    // which is very close to 10 seconds after the speech expression ends.
    const id = setTimeout(() => setIsShowRequestMoreTimeButton(true), 15000);
    setTimeoutId(id);
  });

  useDataTrack((e) => {
    if (timerValue && e.event_type === "speech_end" && !selectedIndex) {
      setTimerTimeRemaining(timerValue);
    }
  });

  useDataTrack((e) => {
    if (e.event_type !== "state_change") return;

    const data = { ...e?.data?.context, event_id: e.event_id };

    if (data?.challenge){
      /* We parse the challenge from the data directly because the activity.challenge 
      fields are merged, which causes different MC and Open response challenge fields
      from consecutive questions to be merged and displayed into one challenge, which 
      is problematic for rendering. */
      setChallenge(data?.challenge);
    }

    updateActivity(data, "multiple-choice");

    if (e?.data?.state === "challenge") {
      setHideQuestion(true); // Unmounting is required for correct MathJax rendering between challenges.
      setHideQuestion(false);
      setSelectedIndex(null);
      setTimerTimeRemaining(null);
      setTimerValue(null);
      setIsShowRequestMoreTimeButton(false);
      setTimeoutId(null);
      setDisableOptions(true);
      setIsChallengeSolved(false);
    }

    if (e?.data?.state === "gather") {
      setHideQuestion(false);
      setSelectedIndex(null);
      setTimerTimeRemaining(null);
      setTimerValue(null);
      setIsShowRequestMoreTimeButton(false);
      setTimeoutId(null);
      setDisableOptions(false);
      setIsChallengeSolved(false);
    }

    if (e?.data?.state === "discuss") {
      setHideQuestion(false);
      setTimerTimeRemaining(null);
      setTimerValue(null);
      setIsShowRequestMoreTimeButton(false);
      setTimeoutId(null);
      setSelectedIndex(null);
      setDisableOptions(true);
      setIsChallengeSolved(false);
    }

    if (e?.data?.state === "solution") {
      setSelectedIndex(null);
      setDisableOptions(true);
      setTimerTimeRemaining(null);
      setTimerValue(null);
      setIsShowRequestMoreTimeButton(false);
      setTimeoutId(null);
      setHideQuestion(false);
      setIsChallengeSolved(e.data?.context?.challenge_solved);
    }
  });

  const onORAnswer = async (solution) => {
    openResponseQuestionResponse(activity, solution);
    setSelectedIndex("flagging OR answer submission");
    setIsShowRequestMoreTimeButton(false);
    setTimerTimeRemaining(null);
  }

  const onMCAnswer = async (option, index) => {
    if (disableOptions) return;
    if (!option.eligible) return;
    multipleChoiceQuestionResponse(activity, index);
    setSelectedIndex(index);
    setIsShowRequestMoreTimeButton(false);
    setTimerTimeRemaining(null);
  };

  useEffect(() => {
    if (!isVideoToggled && isVideoEnabled) {
      toggleVideoEnabled();
      setIsVideoToggled(true);
    }
  }, []);

  useEffect(()=> {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typeset()
    }
  },[activity]);

  if (!challenge) return null;

  return (
    <StyledFullscreen>
      <MultipleChoiceActivityRender
        hideQuestion={hideQuestion}
        timerTimeRemaining={timerTimeRemaining}
        isShowRequestMoreTimeButton={isShowRequestMoreTimeButton}
        selectedIndex={selectedIndex}
        challenge={challenge}
        disableOptions={disableOptions}
        onMCAnswer={onMCAnswer}
        onORAnswer={onORAnswer}
        isMathJaxSet={true}
        isChallengeSolved={isChallengeSolved}
      />
    <div style={{display: "none"}}>
      <VideosBar />
    </div>
  </StyledFullscreen>
  );
};

export const StyledFullscreen = styled(Fullscreen)`
  display: grid;
  align-items: center;
  justify-items: center;
  max-height: calc(100vh - 80px);
  min-height: 100%;
`;
