import { colors } from "utils";
import { Counter } from "./Counter";

export const Badge = ({ number, description }) => {

  return (
    <div style={{
      backgroundColor: colors.blue200,
      borderRadius: "100px",
      display: "flex",
      flexDirection: "row",
      height: "20px",
      gap: "2px",
      maxWidth: "max-content",
      padding: "0 6px" }}>
      <Counter number={number}></Counter>
      <div style={{
        alignSelf: "center",
        boxSizing: "content-box",
        color: colors.blue950,
        display: "inline-flex",
        fontSize: "12px",
        fontWeight: "bold",
        width: "100%" }}>
        <p>
          {description}
        </p>
      </div>
    </div>
  );
};
