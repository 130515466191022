import * as Sentry from "@sentry/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "@bootstrap-styled/v4";
import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { request } from "hooks/useOkoApi/useOkoApi";
import { MultipleChoiceActivityRender } from "../MultipleChoiceActivityRender";
import { buildPreviewChallenge, handleSaveChallengeError } from "utils/challenge";
import { constants } from "utils";

export const PreviewMultipleChoiceQuestionModal = ({ challenge, isModalOpen, setIsModalOpen }) => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setIsModalOpen(false);
    setError(null);
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      await request('questions/update', {
        payload: challenge
      });
      setIsLoading(false);
      setIsModalOpen(false);
    } catch (e) {
      setIsLoading(false);
      Sentry.captureException(e);
      setError(e?.detail || e?.message || "An unknown error occurred.");
    }
  };

  return (
    <div>
      <Modal size="lg" isOpen={isModalOpen} toggle={handleClose}>
        <ModalHeader>Preview Challenge</ModalHeader>
        <ModalBody>
          <MultipleChoiceActivityRender
            hideQuestion={false}
            timerTimeRemaining={null}
            selectedIndex={null}
            challenge={buildPreviewChallenge(challenge)}
            disableOptions={null}
            onMCAnswer={() => {}}
            onORAnswer={() => {}}
            isMathJaxSet={false} // Needed to prevent race condition on MathJax typesetting.
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={isLoading} onClick={handleSave}>
            Save
          </Button>
          <Button color="secondary" disabled={isLoading} onClick={handleClose}>
            Cancel
          </Button>
        </ModalFooter>
        {error && <Alert style={{ overflowWrap: "break-word" }}
                         dismissible
                         onClose={() => setError(null)}
                         variant="danger">
          {JSON.stringify(handleSaveChallengeError(constants.CONSENSUS_ENUM, error))}
        </Alert>}
        {isLoading && <div style={{ alignSelf: "center", position: "fixed", top: "50%" }}>
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            size="5x"
          />
        </div>}
      </Modal>
    </div>
  );
};
