import { Redirect } from "react-router-dom";
import { Home } from "components/pages/Home/Home";
import { Session } from "components/pages/Session/Session";
import { NotFound } from "components/pages/NotFound/NotFound";
import { Login } from "components/pages/Home/Login";
import {
  MultipleChoiceQuestionRender,
} from "components/pages/Session/Activity/activities/MultipleChoiceActivity/Edit/MultipleChoiceQuestionRender";
import {
  MultipleChoiceQuestionView
} from "components/pages/Session/Activity/activities/MultipleChoiceActivity/Edit/MultipleChoiceQuestionView";
import {
  CrazyCalculatorQuestionRender
} from "components/pages/Session/Activity/activities/CalculatorActivity/Edit/CrazyCalculatorQuestionRender";
import {
  CrazyCalculatorQuestionView
} from "components/pages/Session/Activity/activities/CalculatorActivity/Edit/CrazyCalculatorQuestionView";
import { GroupCodeView } from "components/pages/Educator/Dashboard/GroupCodeView";
import { SidebarView } from "components/SidebarView";
import { colors, constants } from "utils";
import { SessionHistory } from "components/pages/Educator/Session/SessionHistory";
import { SettingsModal } from "components/pages/Educator/SignUp/SettingsModal";
import { Observe } from "components/pages/Session/Observe/Observe";
import { SessionSummaryPromptConsole } from "components/pages/Admin/SessionSummaryPromptConsole";
import { FacilitationAIPromptConsole } from "components/pages/Admin/FacilitationAIPromptConsole";

export const routes = [
  {
    name: "Home",
    path: "/",
    exact: true,
    component: Home,
  },
  {
    name: "Session",
    path: "/session/:sessionId",
    component: Session,
  },
  {
    path: "/educator/signup",
    component: () => <Login isSignUp />,
  },
  {
    path: constants.EDUCATOR_SETTINGS_PATH,
    name: constants.EDUCATOR_SETTINGS_ROUTE_NAME,
    component: () => <SettingsModal isOpen isOnboarding setIsOpen={()=>{}} />,
  },
  {
    path: constants.EDUCATOR_LOGIN_PATH,
    name: constants.EDUCATOR_LOGIN_ROUTE_NAME,
    component: () => <Login redirectPath={constants.EDUCATOR_ROUTE_NAME}/>,
  },
  {
    path: constants.ADMIN_LOGIN_PATH,
    name: constants.ADMIN_LOGIN_ROUTE_NAME,
    component: () => <Login redirectPath={constants.SESSION_SUMMARY_PROMPT_CONSOLE_ROUTE_NAME}/>,
  },
  {
    path: constants.EDUCATOR_PATH,
    name: constants.EDUCATOR_ROUTE_NAME,
    component: () => <>
      <SidebarView sideVariant="educator" viewComponent={
        <div style={{ backgroundColor: colors.milkGlass200, paddingTop: "24px", minHeight: "calc(100vh - 92px)" }}>
          <GroupCodeView />
        </div>} headerTitle={constants.DASHBOARD}>
      </SidebarView>
    </>,
  },
  {
    path: constants.SESSION_HISTORY_PATH,
    name: constants.SESSION_HISTORY_ROUTE_NAME,
    component: () => <>
      <SidebarView sideVariant="educator" viewComponent={
        <div style={{ backgroundColor: colors.milkGlass200, padding: "24px", minHeight: "calc(100vh - 92px)" }}>
          <SessionHistory/>
        </div>} headerTitle={constants.SESSION_HISTORY}>
      </SidebarView>
    </>,
  },
  {
    path: constants.SESSION_SUMMARY_PROMPT_CONSOLE_PATH,
    name: constants.SESSION_SUMMARY_PROMPT_CONSOLE_ROUTE_NAME,
    component: () => <>
      <SidebarView sideVariant="admin" viewComponent={
        <div style={{ backgroundColor: colors.milkGlass200, padding: "24px", minHeight: "calc(100vh - 92px)" }}>
          <SessionSummaryPromptConsole/>
        </div>} headerTitle={constants.SESSION_SUMMARY_PROMPT_CONSOLE}>
      </SidebarView>
    </>,
  },
  {
    path: constants.FACILITATION_AI_PROMPT_CONSOLE_PATH,
    name: constants.FACILITATION_AI_PROMPT_CONSOLE_ROUTE_NAME,
    component: () => <>
      <SidebarView sideVariant="admin" viewComponent={
        <div style={{ backgroundColor: colors.milkGlass200, padding: "24px", minHeight: "calc(100vh - 92px)" }}>
          <FacilitationAIPromptConsole/>
        </div>} headerTitle={constants.FACILITATION_AI_PROMPT_CONSOLE}>
      </SidebarView>
    </>,
  },
  {
    path: constants.CONSENSUS_QUESTIONS_VIEW_PATH,
    name: constants.CONSENSUS_QUESTIONS_VIEW_ROUTE_NAME,
    component: () => <>
      <SidebarView sideVariant="admin" viewComponent={
        <div style={{ backgroundColor: colors.milkGlass200, padding: "24px", minHeight: "calc(100vh - 92px)" }}>
          <MultipleChoiceQuestionView/>
        </div>} headerTitle={constants.CONSENSUS_QUESTIONS_VIEW}>
      </SidebarView>
    </>,
  },
  {
    path: constants.CONSENSUS_QUESTIONS_RENDER_PATH,
    name: constants.CONSENSUS_QUESTIONS_RENDER_ROUTE_NAME,
    component: () => <>
      <SidebarView sideVariant="admin" viewComponent={
        <div style={{ backgroundColor: colors.milkGlass200, padding: "24px", minHeight: "calc(100vh - 92px)" }}>
          <MultipleChoiceQuestionRender/>
        </div>} headerTitle={constants.CONSENSUS_QUESTIONS_RENDER}>
      </SidebarView>
    </>,
  },
  {
    path: constants.CRAZY_CALCULATOR_QUESTIONS_VIEW_PATH,
    name: constants.CRAZY_CALCULATOR_QUESTIONS_VIEW_ROUTE_NAME,
    component: () => <>
      <SidebarView sideVariant="admin" viewComponent={
        <div style={{ backgroundColor: colors.milkGlass200, padding: "24px", minHeight: "calc(100vh - 92px)" }}>
          <CrazyCalculatorQuestionView/>
        </div>} headerTitle={constants.CRAZY_CALCULATOR_QUESTIONS_VIEW}>
      </SidebarView>
    </>,
  },
  {
    path: constants.CRAZY_CALCULATOR_QUESTIONS_RENDER_PATH,
    name: constants.CRAZY_CALCULATOR_QUESTIONS_RENDER_ROUTE_NAME,
    component: () => <>
      <SidebarView sideVariant="admin" viewComponent={
        <div style={{ backgroundColor: colors.milkGlass200, padding: "24px", minHeight: "calc(100vh - 92px)" }}>
          <CrazyCalculatorQuestionRender/>
        </div>} headerTitle={constants.CRAZY_CALCULATOR_QUESTIONS_RENDER}>
      </SidebarView>
    </>,
  },
  {
    path: "/admin*",
    component: () => <Redirect to={constants.SESSION_SUMMARY_PROMPT_CONSOLE_PATH} />,
  },
  {
    path: "/observe/:groupCode",
    name: "Observe",
    component: Observe,
  },
  {
    component: NotFound,
  },
];
