import "./groupCodeButton.css";
import { useState } from "react";
import { Button } from "react-bootstrap";
import IconCopySimple from "assets/Icon-CopySimple.svg";

export const GroupCodeButton = ({ code }) => {
  const [buttonText, setButtonText] = useState(code.toUpperCase());
  const handleClick = (e) => {
    navigator.clipboard.writeText(process.env.REACT_APP_OKOLABS_APP_URL + "?code=" + e.target.innerText)
      .then(() => {
        setButtonText("Copied");
        setTimeout(() => setButtonText(code.toUpperCase()), 2000);
      });
  }
  return (
    <div>
      <Button className="group-code-button" onClick={handleClick}>
        <div style={{ alignItems: "center", display: "flex", gap: "4px", justifyContent: "center" }}>
          <img src={IconCopySimple} />
          {buttonText}
        </div>
      </Button>
    </div>
  );
};
