import "./multipleChoiceActivity.css"
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Alert, Card, ListGroup } from "@bootstrap-styled/v4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import * as Sentry from "@sentry/react";
import { request } from "hooks/useOkoApi/useOkoApi";
import { Timer } from "./CountdownTimer";
import { VerticalFraction } from "./VerticalFraction";
import { MathJax } from "./MathJax";
import { useSessionContext } from "hooks/useSession";
import { Celebration } from "../../../Celebration/Celebration";
import { Button } from "react-bootstrap";
import { colors, validSolution } from "utils";

const OpenResponseWidget = ({onORAnswer, init_solution, disabled, isDataStateReset}) => {
  /* 
  OpenResponse Question Widget (Textbox and Submit Button) 
  When the user clicks the button, it will call onORAnswer with the solution
  and flash.
  */
  const [solution, setSolution] = useState(init_solution ? init_solution: "");
  const [isBlinking, setIsBlinking] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const handleClick = () => {
    if (!isChanged && !isDataStateReset) return;
    setIsSubmitted(true);
    setIsBlinking(true);
    setTimeout(() => setIsBlinking(false), 250);
    onORAnswer(solution);
    setIsChanged(false);
  };

  const clickStyle = `
    @keyframes blink-animation {
      50% {
      transform: scale(1.2);
      }
    }
  `;

  const buttonStyle = {
    backgroundColor: colors.blue500,
    border: "none",
    borderRadius: "1rem",
    animation: isBlinking ? "blink-animation 0.25s ease-in-out" : "none",
    padding: "12px 24px" 
  };

  const disabledButtonStyle = {
    backgroundColor: colors.blue300,
    cursor: "not-allowed",
    pointerEvents: "auto"
  };

  const submittedButtonStyle = {
    backgroundColor: colors.nightlyWoods500,
    cursor: "not-allowed"
  };

  const resubmittedButtonStyle = {
    backgroundColor: colors.nightlyWoods500,
    opacity: 0.75
  };

  const inputStyle = {
    width: "150px",
    borderRadius: "1rem",
    border: `1px solid ${colors.milkGlass950}`,
    backgroundColor: colors.neutral50,
    outline: "none",
    textAlign: "center"
  };

  const disabledInputStyle = {
    cursor: "not-allowed",
    border: "1px solid rgba(0, 0, 0, 0.125)",
    background: colors.nightlyWoods100
  };

  const isButtonDisabled = () => {
    // Disable button if solution is invalid (e.g. "" or "-")
    return (disabled || validSolution(solution)[0])
  };

  let buttonText = "Submit";

  const appliedButtonStyle = () => {
    if (isButtonDisabled()) return {...buttonStyle, ...disabledButtonStyle};
    if (isChanged && isSubmitted) {
      buttonText = "Resubmit";
      return {...buttonStyle, ...resubmittedButtonStyle};
    }
    if (isSubmitted && !isDataStateReset) {
      buttonText = "Submitted";
      return {...buttonStyle, ...submittedButtonStyle};
    }
    return buttonStyle;
  }

  return <div style={{ alignSelf: "center", display: "flex", gap: "32px" }}>
    <input
      className="answer"
      type="text"
      disabled={disabled}
      value={solution}
      onChange = {(e) => {
        const value = e.target.value;
        if (validSolution(value)[1]) {
          // We want the user to be able to enter a minus sign,
          // but technically it is not a valid solution (yet)
          setSolution(value);
          setIsChanged(true);
        }
      }}
      style={ disabled ? {...inputStyle, ...disabledInputStyle} : inputStyle }
    />
    <style>{clickStyle}</style>
    <Button
      disabled={isButtonDisabled()}
      onClick={handleClick}
      style={appliedButtonStyle()}
      type="submit">
      {buttonText}
    </Button>
  </div>
};

export const MultipleChoiceActivityRender = ({
                                             hideQuestion,
                                             timerTimeRemaining,
                                             isShowRequestMoreTimeButton,
                                             selectedIndex,
                                             challenge,
                                             disableOptions,
                                             onMCAnswer,
                                             onORAnswer,
                                             isMathJaxSet,
                                             isChallengeSolved }) => {

  const { isCelebrating, setIsCelebrating, session } = useSessionContext();
  const [isMoreTimeRequested, setIsMoreTimeRequested] = useState(false);

  const dispatchMoreTimeRequest = async () => {
    await request(`session/${session.id}/challenge/ask_for_more_time`, {
      }).then(() => setIsMoreTimeRequested(true)
    ).catch((e) => {
      console.log(e);
      Sentry.captureException(e);
    });
  };

  const renderPromptImage = (url) => {
    if (!url || url === 'nan') {
      return null;
    } else {
      return <img src={url} style={{
        alignSelf: "center",
        maxHeight: "fit-content",
        maxWidth: "-webkit-fill-available",
        padding: '20px 0' }} alt="Prompt image"/>
    }
  };

  const renderedPrompt = challenge.render_type === 'math' ?
    // Can not use handleMultiLine function here as it could match MathJax's \ne in addition to \n
    <MathJax expression={challenge.prompt} isMathJaxSet={isMathJaxSet} /> :
    <>
      {handleMultiLine(challenge.prompt)}
    </>;

  useEffect(() => {
    if (isChallengeSolved) {
      setIsMoreTimeRequested(false);
      setIsCelebrating(true);
    }
    return () => {
      setIsCelebrating(false);
    }
  }, [isChallengeSolved]);

  const moreTimeButtonStyle = {
    backgroundColor: colors.blue500,
    border: "none",
    borderRadius: "100px"
  };

  const disabledMoreTimeButtonStyle = {
    cursor: "not-allowed",
    pointerEvents: "auto"
  };

  return (
    <>
      {isCelebrating && <Celebration />}
      {!hideQuestion && (
        <Card className="p-5" style={{ borderRadius: "1rem", maxWidth: 800, position: "relative", top: "34px" }}>
          {timerTimeRemaining && timerTimeRemaining > 1 && (
            <Card
              className="p-3 mb-2 "
              style={{
                display: "flex",
                position: "absolute",
                left: 0,
                top: 0,
                transform: "translate(-30px, -50%)",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "1.2rem",
                borderRadius: 12,
              }}
            >
              <Timer timeRemaining={timerTimeRemaining}/>
              <span className="mr-2 ml-2">
                {timerTimeRemaining} seconds remain!
              </span>
              {isShowRequestMoreTimeButton && <Button
                disabled={isMoreTimeRequested}
                onClick={dispatchMoreTimeRequest}
                style={!isMoreTimeRequested ? moreTimeButtonStyle :
                  {...moreTimeButtonStyle, ...disabledMoreTimeButtonStyle}
                }
                type="submit">
                We need more time
              </Button>}
            </Card>
          )}

          {selectedIndex !== null && (
            <Alert
              style={{
                display: "flex",
                position: "absolute",
                left: 0,
                top: 0,
                transform: "translate(-30px, -50%)",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "1.2rem",
                borderRadius: 12,
              }}
            >
              Thanks for picking an answer!
            </Alert>
          )}
          <span className="prompt">
            {renderedPrompt}
          </span>
          {renderPromptImage(challenge.internal_image_url ?? challenge.prompt_image_url)}
          {challenge?.answer_options ? <ListGroup style={{ border: "none", translate: "-21px" }}>
            {challenge.answer_options.map((option, index) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <FontAwesomeIcon
                  color={"#002e19"}
                  size="3x"
                  icon={faCheckCircle}
                  style={{ marginBottom: "1rem", marginRight: "1rem",
                    opacity: challenge.solution_index === index ? 1 : 0 }}
                />
                <Option
                  className={
                    `w-100 d-flex
                    ${!option.eligible || disableOptions ? "disabled" : ""}
                    ${selectedIndex === index ? "selected" : ""}`
                  }
                  key={index + option.id}
                  style={{ marginBottom: "1rem", padding: "1rem", opacity: option.eligible ? 1 : 0.3 }}
                  onClick={() => {
                    onMCAnswer(option, index);
                  }}
                >
                  <div>
                    <span key={index + option.id + "_number"} style={{ fontSize: 14, marginRight: "0.5rem" }}>
                      {numberToLetters(index)} )
                    </span>
                    <span className="answer" key={index + option.id + "_option"}>
                      {challenge.render_type === 'math' ?
                        (
                          <MathJax expression={option.text} isMathJaxSet={isMathJaxSet} />
                        ) : (
                          <>
                            {handleVerticalFractions(option.text)}
                          </>
                        )}
                    </span>
                  </div>
                </Option>
              </div>
            ))}
          </ListGroup> : (
            <OpenResponseWidget
              init_solution={challenge?.solution}
              onORAnswer={onORAnswer}
              disabled={disableOptions}
              isDataStateReset={!selectedIndex}
            />
          )}
        </Card>
      )}
    </>
  );
};

const Option = styled(Card)`
  border-radius: 1rem !important;
  cursor: pointer;
  font-size: 1.3rem;
  flex-direction: row !important;
  align-items: center !important;
  background: #eaeafc !important;

  &:hover {
    background-color: #d3d3f8 !important;
  }

  &.disabled {
    cursor: not-allowed;
    background: #e7e7e7 !important;
  }

  &.selected {
    background: #9c9bf1 !important;
    box-shadow: 0 0 0 0.2rem #524fc9 !important;
`;

//convert number to abc etc
const numberToLetters = (number) => {
  var abc = "abcdefghijklmnopqrstuvwxyz";
  var abcLength = abc.length;
  var converted = "";

  while (number >= 0) {
    converted = abc.charAt(number % abcLength) + converted;
    number = Math.floor(number / abcLength) - 1;
  }

  return converted;
};

const handleMultiLine = (text) => {
  const newLine = '\\n'
  if(!text.includes(newLine)) return handleVerticalFractions(text);

  return text.split(newLine).map(
    (line, index) => (
      <React.Fragment key={index}>
        <div>{handleVerticalFractions(line)}</div>
        <br />
      </React.Fragment>
    ))
};

const handleVerticalFractions = (text) => {
  if (!text) return text;
  text = text.replace(/\u00a0/g, " "); // this replaces all NBSP characters with space
  if (text.indexOf("/") < 1) return text; // also handling a case where "/" is at index 0 - not a valid fraction
  const words = text.split(" ");
  let insertSpaceAfterFraction = false;
  for (let i = 0; i < words.length; i++) {
    if (words[i].indexOf("/") > 0) { // word with "/" at index 0 is not a valid fraction
      let top = words[i].split("/")[0];
      let charsBeforeFraction = "";
      // Handling a case where there is non-number char in the top, like opening parenthesis
      for (let j = 0; j < top.length; j++) {
        if (!isNumber(top.charAt(j))) {
          charsBeforeFraction = charsBeforeFraction + top.charAt(j);
        } else {
          break;
        }
      }
      top = top.slice(charsBeforeFraction.length);
      let bottom = words[i].split("/")[1];
      let charsAfterFraction = "";
      // Handling a case where there is no space after the fraction and non-number char goes next like "1/8?"
      for (let j = 0; j < bottom.length; j++) {
        if (!isNumber(bottom.charAt(j))) {
          charsAfterFraction = bottom.substring(j, bottom.length);
          bottom = bottom.substring(0, j);
          break;
        }
      }
      words[i] = <span>
        {charsBeforeFraction && <span>{charsBeforeFraction}</span>}
        <VerticalFraction key={i} numerator={top} denominator={bottom}/>
        {charsAfterFraction && <span>{charsAfterFraction}</span>}
      </span>;
      insertSpaceAfterFraction = true;
    } else {
      if (insertSpaceAfterFraction) {
        words[i] = <span key={i}>{" " + words[i] + " "}</span>;
      } else {
        words[i] = <span key={i}>{words[i] + " "}</span>;
      }
      insertSpaceAfterFraction = false;
    }
  }
  return words;
};

const isNumber = (str) => {
  if (typeof str != "string") return false
  return !isNaN(str) && !isNaN(parseFloat(str));
};
