import { Button, H3, Modal, ModalBody, ModalFooter, ModalHeader } from "@bootstrap-styled/v4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as Sentry from "@sentry/react";
import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { useButtonIds } from "hooks/activities/calculator/useButtonMaps/useButtonMaps";
import { request } from "hooks/useOkoApi/useOkoApi";
import { buttonIdsToStr, handleSaveChallengeError } from "utils/challenge";
import { constants } from "utils";
import { CalculatorRender } from "../Calculator/CalculatorRender";

export const PreviewCrazyCalculatorQuestionModal = ({ challenge, isModalOpen, setIsModalOpen }) => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const buttonIds = useButtonIds();

  const handleClose = () => {
    setIsModalOpen(false);
    setError(null);
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      await request('questions/update', {
        payload: challenge
      });
      setIsModalOpen(false);
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      Sentry.captureException(e);
      setError(e?.detail || e?.message || "An unknown error occurred.");
    }
  };

  return (
    <Modal size="lg" isOpen={isModalOpen} toggle={handleClose}>
      <ModalHeader>Preview Challenge</ModalHeader>
      <ModalBody style={{ alignSelf: "center" }}>
        <H3>Try to make the number {challenge.target_answer}.</H3>
        <CalculatorRender
          buttonIds={buttonIdsToStr(buttonIds)}
          bannedButtonIds={challenge.broken_keys}
          formula={""}
          clear={() => {}}
          onButtonClick={() => {}}
          buttonAnimTrigger={{}}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" disabled={isLoading} onClick={handleSave}>
          Save
        </Button>
        <Button color="secondary" disabled={isLoading} onClick={handleClose}>
          Cancel
        </Button>
      </ModalFooter>
      {error && <Alert style={{ overflowWrap: "break-word" }}
                       dismissible
                       onClose={() => setError(null)}
                       variant="danger">
        {JSON.stringify(handleSaveChallengeError(constants.CRAZY_CALCULATOR_ENUM, error))}
      </Alert>}
      {isLoading && <div style={{ alignSelf: "center", position: "fixed", top: "50%" }}>
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          size="5x"
        />
      </div>}
    </Modal>
  );
};
