import Accordion from "react-bootstrap/Accordion";
import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Form } from "react-bootstrap";

export const LLMEventItem = forwardRef(({
                                          activeKey,
                                          content,
                                          name,
                                          role,
                                          index,
                                          setLlmEventItem
                                        }, ref) => {
  const [newName, setNewName] = useState(name);
  const [newContent, setNewContent] = useState(content);
  const textareaRef = useRef(content);

  useImperativeHandle(ref, () => ({
    adjustTextArea: () => {
      requestAnimationFrame(() => {
        adjustTextAreaHeight();
      });
    }
  }));

  const adjustTextAreaHeight = () => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleContentChange = (e) => {
    setNewContent(e.target.value);
    setLlmEventItem(index, e.target.value, newName, role);
    adjustTextAreaHeight();
  };

  const handleNameChange = (e) => {
    setNewName(e.target.value);
    setLlmEventItem(index, newContent, e.target.value, role);
  };

  return (
    <Accordion.Item
      eventKey={index}
      key={index}
      style={{ border: "1px solid #ABB1BA", borderRadius: "12px", padding: "16px 0" }}>
      <Accordion.Header>
        <div style={{ display: "flex", flexDirection: "row", gap: "24px" }}>
          <p style={{ fontSize: "18px", fontWeight: 700, lineHeight: "26px" }}>
            {activeKey === "0" ? "Click to hide AI look for metadata..." :
              "Click to expand and view AI look for metadata..."}
          </p>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div style={{ display: "flex", flexDirection: "column", gap: "32px" }}>
          <Form.Group style={{ width: "100%" }}>
            <Form.Label style={{ fontWeight: "bold" }}>Content (Timestamp and Speech)</Form.Label>
            <Form.Control
              ref={textareaRef}
              as="textarea"
              required
              type="text"
              placeholder="Enter Content"
              value={newContent}
              onChange={handleContentChange}
            />
          </Form.Group>
          <Form.Group style={{ width: "100%" }}>
            <Form.Label style={{ fontWeight: "bold" }}>User Id</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Enter User Id"
              value={newName}
              onChange={handleNameChange}
            />
          </Form.Group>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
});
