import "./sessionSummaryPromptConsole.css";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Button, Form } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import * as Sentry from "@sentry/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { colors, constants, generateRoutePath } from "utils";
import { useAuthUserData } from "hooks/useAuthUserData/useAuthUserData";
import { request } from "hooks/useOkoApi/useOkoApi";
import IconInfo from "assets/Icon-Info.svg";

export const SessionSummaryPromptConsole = () => {
  const { authUserData, loading } = useAuthUserData();
  const [formData, setFormData] = useState({});
  const [sessionFormData, setSessionFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [sessionSummaryData, setSessionSummaryData] = useState();
  const [newSessionSummary, setNewSessionSummary] = useState();
  const history = useHistory();
  const promptTextareaRef = useRef(null);
  const summaryTextareaRef = useRef(null);
  const [activeKey, setActiveKey] = useState(null);

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  const adjustPromptHeight = () => {
    if (promptTextareaRef.current) {
      const textarea = promptTextareaRef.current;
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const adjustSummaryHeight = () => {
    if (summaryTextareaRef.current) {
      const textarea = summaryTextareaRef.current;
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  if (loading) return <></>;
  if (authUserData.isAnonymous) history.push(generateRoutePath(constants.ADMIN_LOGIN_ROUTE_NAME));

  const handleGetSessionSummaryData = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setNewSessionSummary("");
    await request(`session_summarization/${sessionFormData.session_id}/summary`, {
      method: "GET",
    }).then((response) => {
      setIsLoading(false);
      setFormData(
        {
          prompt: response.summary_input.prompt,
          llm: response.summary_input.llm,
          temperature: response.summary_input.temperature,
        }
      );
      setSessionSummaryData(response);
      adjustPromptHeight();
      adjustSummaryHeight();
    }).catch((e) => {
      console.log(e);
      setIsLoading(false);
      Sentry.captureException(e);
      const errors = {};
      if (e.detail && Array.isArray(e.detail)) {
        e.detail.forEach((error) => {
          errors[error.loc.pop()] = error.msg;
        });
      } else {
        errors["common"] = e?.detail || e?.message || "An unknown error occurred.";
      }
      setError(errors);
    });
  };

  const handleChangeSessionIdForm = (e) => {
    const { name, value } = e.target;
    setSessionFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const alertStyle = {
    padding: "8px",
    marginBottom: 0,
    marginTop: "16px"
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleGenerate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    await request(`session_summarization/generate_summary`, {
      payload: {
        prompt: formData.prompt,
        llm: formData.llm,
        temperature: formData.temperature,
        events: sessionSummaryData.summary_input.events,
        standards: sessionSummaryData.summary_input.standards
      }
    }).then((response) => {
      setIsLoading(false);
      setNewSessionSummary(response);
    }).catch((e) => {
      console.log(e);
      setIsLoading(false);
      Sentry.captureException(e);
      const errors = {};
      if (e.detail && Array.isArray(e.detail)) {
        e.detail.forEach((error) => {
          errors[error.loc.pop()] = error.msg;
        });
      } else {
        errors["generation"] = e?.detail || e?.message || "An unknown error occurred.";
      }
      setError(errors);
    });
  };

  return (
    <div style={{
      display: "flex",
      backgroundColor: colors.milkGlass200,
      flexDirection: "column",
      gap: "24px" }}>
      <div style={{
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: "12px",
        boxShadow: "0px 1px 2px 0px rgba(14, 26, 49, 0.05)" }}>
        <div>
          <Alert style={{ marginBottom: 0 }}
                 variant="">
            <div style={{ display: "flex", gap: "16px" }}>
              <div style={{
                alignItems: "center",
                display: "flex",
                minHeight: "36px",
                gap: "12px",
                flex: "1 0 0",
                paddingRight: "28px" }}>
                <img src={IconInfo} />
                {constants.SESSION_SUMMARY_PROMPT_CONSOLE_MESSAGE}
              </div>
            </div>
          </Alert>
        </div>
      </div>
      <div style={{
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: "12px",
        boxShadow: "0px 1px 2px 0px rgba(14, 26, 49, 0.05)",
        padding: "32px" }}>
        <Form onSubmit={handleGetSessionSummaryData}>
          <div style={{ display: "flex", flexDirection: "column" , gap: "32px" }}>
            <div style={{ display: "flex", flexDirection: "row" , gap: "32px", justifyContent: "space-between" }}>
              <div style={{ display: "flex", flexDirection: "column" , gap: "24px", width: "100%" }}>
                <Form.Group>
                  <Form.Label style={{ fontWeight: "bold" }}>Session Id</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter session id"
                    value={sessionFormData.session_id}
                    name="session_id"
                    onChange={handleChangeSessionIdForm}
                  />
                  {error?.session_id && (
                    <Alert style={alertStyle} variant="danger">{error.session_id}</Alert>
                  )}
                </Form.Group>
              </div>
              <Button style={{
                alignSelf: "end",
                backgroundColor: colors.nightlyWoods500,
                border: "none",
                borderRadius: "6px",
                fontSize: "16px",
                height: "40px",
                padding: "0 16px",
                width: "100%" }}
                      disabled={isLoading}
                      type="submit">
                Submit
              </Button>
            </div>
            {error?.common && (
              <Alert style={alertStyle} variant="danger">{error.common}</Alert>
            )}
          </div>
        </Form>
      </div>
      {sessionSummaryData && <div style={{
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: "12px",
        boxShadow: "0px 1px 2px 0px rgba(14, 26, 49, 0.05)",
        padding: "32px" }}>
        <Form onSubmit={handleGenerate}>
          <div style={{ display: "flex", flexDirection: "column" , gap: "32px" }}>
            <div style={{ display: "flex", flexDirection: "column" , gap: "24px" }}>
              <Form.Group>
                <Form.Label style={{ fontWeight: "bold" }}>Prompt</Form.Label>
                <Form.Control
                  ref={promptTextareaRef}
                  required
                  as="textarea"
                  placeholder="Enter new prompt"
                  value={formData.prompt}
                  name="prompt"
                  onChange={handleChange}
                  style={{ overflow: "hidden" }}
                />
                {error?.prompt && (
                  <Alert style={alertStyle} variant="danger">{error.prompt}</Alert>
                )}
              </Form.Group>
              <div style={{ display: "flex", gap: "32px" }}>
                <Form.Group style={{ width: "100%" }}>
                  <Form.Label style={{ fontWeight: "bold" }}>LLM Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter LLM name"
                    value={formData.llm}
                    name="llm"
                    onChange={handleChange}
                  />
                  {error?.llm && (
                    <Alert style={alertStyle} variant="danger">{error.llm}</Alert>
                  )}
                </Form.Group>
                <Form.Group style={{ width: "100%" }}>
                  <Form.Label style={{ fontWeight: "bold" }}>LLM Warmth</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    min="0"
                    max="1.5"
                    step="0.01"
                    placeholder="Enter LLM warmth value between 0 and 1.5"
                    value={formData.temperature}
                    name="temperature"
                    onChange={handleChange}
                  />
                  {error?.temperature && (
                    <Alert style={alertStyle} variant="danger">{error.temperature}</Alert>
                  )}
                </Form.Group>
              </div>
              {error?.generation && (
                <Alert style={alertStyle} variant="danger">{error.generation}</Alert>
              )}
            </div>
            <Accordion className="session-summary-prompt-console"
                       activeKey={activeKey}
                       onSelect={(key) => handleToggle(key)}>
              <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                <Accordion.Item
                  eventKey="0"
                  style={{ border: "1px solid #ABB1BA", borderRadius: "12px", padding: "16px 0" }}>
                  <Accordion.Header>
                    <div style={{ display: "flex", flexDirection: "row", gap: "24px" }}>
                      <p style={{ fontSize: "18px", fontWeight: 700, lineHeight: "26px" }}>
                        {activeKey === "0" ? "Click to hide session metadata..." :
                          "Click to expand and view session metadata..."}
                      </p>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                      {JSON.stringify(sessionSummaryData, null, 2)}
                    </pre>
                  </Accordion.Body>
                </Accordion.Item>
              </div>
            </Accordion>
            <Form.Group>
              <Form.Label style={{ fontWeight: "bold" }}>Original Session Summary</Form.Label>
              <Form.Control
                ref={summaryTextareaRef}
                required
                as="textarea"
                disabled
                value={sessionSummaryData.session.summary_text}
                name="prompt"
                style={{ resize: "none", overflow: "hidden" }}
              />
            </Form.Group>
            <Button style={{
              backgroundColor: colors.nightlyWoods500,
              border: "none",
              borderRadius: "6px",
              fontSize: "16px",
              height: "40px",
              padding: "0 16px" }}
                    disabled={isLoading}
                    type="submit">
              Generate new session summary
            </Button>
          </div>
        </Form>
      </div>}
      {isLoading && <div style={{ alignSelf: "center", position: "fixed", top: "50%" }}>
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          size="5x"
        />
      </div>}
      {newSessionSummary && <div style={{
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: "12px",
        boxShadow: "0px 1px 2px 0px rgba(14, 26, 49, 0.05)",
        padding: "32px",
        whiteSpace: "pre-wrap" }}>
        {newSessionSummary}
      </div>}
    </div>
  );
};
