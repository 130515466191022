export const isMobile = (() => {
  if (
    typeof navigator === "undefined" ||
    typeof navigator.userAgent !== "string"
  ) {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

export const validSolution = (value) => {
  /* 
  Validate that the solution is a number 
  Returns (empty, ValidNumber)

  For example, if the value is an empty string or '-', the function will return (true, false)
  If the value is a number, the function will return (false, true)
  If the value is 'some', this will return (false, false)
  */
  const regex = constants.OPEN_RESPONSE_REGEX;
  const empty = value === "" || value === "-";  
  return [empty, regex.test(value)]
}

export const colors = {
  backGroundSecondary: "#F2F4F6",
  gray100: "#F5F5F5",
  gray200: "#CDCDCD",
  blue100: "#EAEAFC",
  blue200: "#D3D3F8",
  blue300: "#BAB9F5",
  blue400: "#9C9BF1",
  blue500: "#605DEC",
  blue800: "#4745AE",
  blue900: "#3A388E",
  blue950: "#292865",
  milkGlass200: "#FDFCFA",
  milkGlass500: "#FAF7F2",
  milkGlass600: "#EEECE7",
  milkGlass800: "#B9B7B3",
  milkGlass950: "#6B6967",
  neutral50: "#FCFCFD",
  neutral100: "#F2F4F7",
  neutral200: "#7A7F89",
  neutral300: "#ABB1BA",
  nightlyWoods100: "#E7E7E7",
  nightlyWoods500: "#002E19",
  nightlyWoods600: "#002C18",
  nightlyWoods700: "#002715",
  nightlyWoods900: "#001C0F",
  nightlyWoods950: "#00140B",
};

export const constants = {
  ACTIVITY: "Activity",
  GRADE_LEVEL: "Grade Level",
  DOMAIN: "Domain",
  CLUSTER: "Cluster",
  STANDARD: "Standard",
  ACTIVITY_PLACEHOLDER: "Select Activity",
  GRADE_LEVEL_PLACEHOLDER: "All Grades",
  DOMAIN_PLACEHOLDER: "All Domains",
  CLUSTER_PLACEHOLDER: "All Clusters",
  STANDARD_PLACEHOLDER: "All Standards",
  CRAZY_CALCULATOR: "Crazy Calculator",
  CRAZY_CALCULATOR_ENUM: "crazy_calculator",
  LETS_TALK: "Let's Talk",
  CONSENSUS_ENUM: "consensus",
  GROUP_CARD_VIEW_MESSAGE: "You can assign a standard to individual group codes by selecting from the drop-downs below",
  CRAZY_CALC_GROUP_CODE_CARD_MESSAGE: "*For Crazy Calculator sessions, OKO dynamically adjusts the difficulty of questions based on student responses.",
  SESSION_SUMMARY_PROMPT_CONSOLE_MESSAGE: "Enter an existing session id to get all of the required data for generating a new session summary.",
  FACILITATION_AI_PROMPT_CONSOLE_MESSAGE: "Enter an existing session id to get all the LLM events that occurred during that session.",
  DASHBOARD: "Dashboard",
  SESSION_HISTORY: "Session History",
  EDUCATOR_PATH: "/educator",
  EDUCATOR_ROUTE_NAME: "EducatorProfile",
  EDUCATOR_LOGIN_PATH: "/educator/login",
  EDUCATOR_LOGIN_ROUTE_NAME: "EducatorLogin",
  ADMIN_LOGIN_PATH: "/admin/login",
  ADMIN_LOGIN_ROUTE_NAME: "AdminLogin",
  SESSION_HISTORY_PATH: "/session_history",
  SESSION_HISTORY_ROUTE_NAME: "SessionHistory",
  STUDENT_LOGIN_CARD_TITLE: "Student Login",
  STUDENT_LOGIN_CARD_MESSAGE: "This is where your students can enter in your Group Codes",
  SETTINGS: "Settings",
  EDUCATOR_SETTINGS_PATH: "/educator/settings",
  EDUCATOR_SETTINGS_ROUTE_NAME: "EducatorSettings",
  STUDENT: "student",
  ANONYMOUS: "anonymous",
  GOOGLE: "google",
  SESSION_SUMMARY_PROMPT_CONSOLE: "Session Summary Prompt Console",
  SESSION_SUMMARY_PROMPT_CONSOLE_PATH: "/admin/session_summary_prompt_console",
  SESSION_SUMMARY_PROMPT_CONSOLE_ROUTE_NAME: "SessionSummaryPromptConsole",
  FACILITATION_AI_PROMPT_CONSOLE: "Faciliatation AI Prompt Console",
  FACILITATION_AI_PROMPT_CONSOLE_PATH: "/admin/facilitation_ai_prompt_console",
  FACILITATION_AI_PROMPT_CONSOLE_ROUTE_NAME: "FacilitationAIPromptConsole",
  CONSENSUS_QUESTIONS_RENDER: "Consensus Challenge Render",
  CONSENSUS_QUESTIONS_RENDER_PATH: "/admin/consensus/questions/render",
  CONSENSUS_QUESTIONS_RENDER_ROUTE_NAME: "AdminConsensusQuestionsRender",
  CONSENSUS_QUESTIONS_VIEW: "Consensus Challenge List",
  CONSENSUS_QUESTIONS_VIEW_PATH: "/admin/consensus/questions/view",
  CONSENSUS_QUESTIONS_VIEW_ROUTE_NAME: "AdminConsensusQuestionsView",
  CRAZY_CALCULATOR_QUESTIONS_RENDER: "Crazy Calc. Challenge Render",
  CRAZY_CALCULATOR_QUESTIONS_RENDER_PATH: "/admin/crazy_calculator/questions/render",
  CRAZY_CALCULATOR_QUESTIONS_RENDER_ROUTE_NAME: "AdminCrazyCalculatorQuestionsRender",
  CRAZY_CALCULATOR_QUESTIONS_VIEW: "Crazy Calc. Challenge List",
  CRAZY_CALCULATOR_QUESTIONS_VIEW_PATH: "/admin/crazy_calculator/questions/view",
  CRAZY_CALCULATOR_QUESTIONS_VIEW_ROUTE_NAME: "AdminCrazyCalculatorQuestionsView",
  OPEN_RESPONSE_REGEX: /^-?\d{0,10}$/ // Max is 9999999999
};


export const ConsensusVariantOptions = [
  { value: 'multiplechoice', label: 'Multiple Choice Format' },
  { value: 'openresponse', label: 'Open Response Format' }
];

export const getActivityName = (activity) => {
  let activityName;
  switch (activity) {
    case constants.CRAZY_CALCULATOR_ENUM:
      activityName = constants.CRAZY_CALCULATOR;
      break;
    case constants.CONSENSUS_ENUM:
      activityName = constants.LETS_TALK;
      break;
    default:
      activityName = "N/A";
  }
  return activityName;
};
