import "./multipleChoiceActivityQuestionView.css"
import React from 'react';
import { useState } from "react";
import * as Sentry from "@sentry/react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { request } from "../../../../../../../hooks/useOkoApi/useOkoApi";
import { useAuthUserData } from "hooks/useAuthUserData/useAuthUserData";
import { useStandards } from "../../../../../../../hooks/useStandards";
import { buildSelectOptions} from "utils/standards";
import { MultipleChoiceQuestion } from "./MultipleChoiceQuestion";
import Accordion from "react-bootstrap/Accordion";
import { EditMultipleChoiceQuestionModal } from "./EditMultipleChoiceQuestionModal";
import { buildEmptyChallenge } from "utils/challenge";
import { ConsensusVariantOptions, constants, generateRoutePath } from "utils";

export const MultipleChoiceQuestionView = () => {
  const { authUserData, loading } = useAuthUserData();
  const history = useHistory();
  const standardsData = useStandards();
  const [questionsData, setQuestionsData] = useState([]);
  const [prefix, setPrefix] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState("multiplechoice");

  const getQuestionsData = async (prefix, selectedFormat) => {

    try {
      setIsLoading(true);
      const questionsData = await request(
        `questions/fetch/${selectedFormat}?fetch_inactive=true${prefix ? `&standard_prefix=${prefix}` : ''}`, {
          method: "GET",
        });
      setQuestionsData(questionsData);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      Sentry.captureException(e);
    }
  };

  const getOptions = () => {
    const options = buildSelectOptions(standardsData);
    options.unshift({"value": null, label: "All available standards"});
    return options;
  };

  const handleOnStandardsChange = (target) => {
    const prefix = target.value;
    setPrefix(prefix);
    getQuestionsData(prefix, selectedFormat);
  };

  const handleOnFormatChange = (target) => {
    setSelectedFormat(target.value);
    getQuestionsData(prefix, target.value);
  };

  const styles = {
    control: (css) => ({
      ...css,
      width: "800px",
    }),
  };

  const handleAddNewChallengeButton = () => {
    setIsModalOpen(true);
  };

  const newChallengeButton = () => {
    return <div className="new-challenge-button">
      <button style={{ borderRadius: "100px", borderWidth: "1px", marginLeft: "18px" }}
              onClick={handleAddNewChallengeButton}>
        <FontAwesomeIcon icon={faPlus}/>
        &nbsp;Add new challenge item
      </button>
    </div>;
  }

  const placeholderPrefix = prefix ? `for standard ${prefix}` : "";

  if (loading) return <></>;
  if (authUserData.isAnonymous) history.push(generateRoutePath(constants.ADMIN_LOGIN_ROUTE_NAME));
  return (
    <div>
      {isLoading && <div style={{ left: "50%", position: "absolute", top: "50%" }}>
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          size="5x"
        />
      </div>}
      <div style={{ alignItems: "center", display: "flex", flexDirection: "column", marginBottom: "15px" }}>
        <p>Fetched {questionsData.length} challenges {placeholderPrefix}</p>
        <br/>
        <Select isDisabled={isLoading} isSearchable placeholder="Select standard..." options={getOptions()}
                onChange={handleOnStandardsChange} styles={styles}/>
        <br/>
        <Select 
          isDisabled={isLoading} 
          value={ConsensusVariantOptions.find(option => option.value === selectedFormat)} // Ensure the selected option matches
          isSearchable 
          placeholder="Select format..." 
          options={ConsensusVariantOptions}
          onChange={handleOnFormatChange} 
          styles={styles}
        />

      </div>
      {!isLoading && newChallengeButton()}
      {!isLoading && <Accordion style={{ display: "flex", flexDirection: "column", gap: "8px", padding: "8px" }}>
        {questionsData.map((challenge, index) => (
          <MultipleChoiceQuestion challenge={challenge} index={index} showHeader />
        ))}
      </Accordion>
      }
      <EditMultipleChoiceQuestionModal challenge={buildEmptyChallenge(selectedFormat === "multiplechoice")}
                                       isModalOpen={isModalOpen}
                                       modalTitle="Create New Challenge"
                                       setIsModalOpen={setIsModalOpen}/>
    </div>
  );
};
