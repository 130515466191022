import "./crazyCalculatorQuestionView.css"
import * as Sentry from "@sentry/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { request } from "hooks/useOkoApi/useOkoApi";
import { useAuthUserData } from "hooks/useAuthUserData/useAuthUserData";
import { CrazyCalculatorQuestion } from "./CrazyCalculatorQuestion";
import { EditCrazyCalculatorQuestionModal } from "./EditCrazyCalculatorQuestionModal";
import { buildEmptyChallenge } from "utils/challenge";
import { constants, generateRoutePath } from "utils";

export const CrazyCalculatorQuestionView = () => {
  const { authUserData, loading } = useAuthUserData();
  const history = useHistory();
  const [questionsData, setQuestionsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getQuestionsData = async () => {
    try {
      setIsLoading(true);
      const questionsData = await request(
        'questions/fetch/crazycalculator?fetch_inactive=true', {
          method: "GET",
        });
      setQuestionsData(questionsData);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      Sentry.captureException(e);
    }
  };

  useEffect( () => {
    getQuestionsData();
  },[]);

  const handleAddNewChallengeButton = () => {
    setIsModalOpen(true);
  };

  const newChallengeButton = () => {
    return <div className="new-challenge-button">
      <button style={{ borderRadius: "100px", borderWidth: "1px", marginLeft: "18px" }}
              onClick={handleAddNewChallengeButton}>
        <FontAwesomeIcon icon={faPlus}/>
        &nbsp;Add new challenge item
      </button>
    </div>;
  }

  if (loading) return <></>;
  if (authUserData.isAnonymous) history.push(generateRoutePath(constants.ADMIN_LOGIN_ROUTE_NAME));
  return (
    <div className="crazy-calculator-question">
      {isLoading && <div style={{ left: "50%", position: "absolute", top: "50%" }}>
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          size="5x"
        />
      </div>}
      <div style={{ alignItems: "center", display: "flex", flexDirection: "column", marginBottom: "15px" }}>
        <p>Fetched {questionsData.length} challenges</p>
      </div>
      {!isLoading && newChallengeButton()}
      {!isLoading && <Accordion style={{ display: "flex", flexDirection: "column", gap: "8px", padding: "8px" }}>
        {questionsData.map((challenge, index) => (
          <CrazyCalculatorQuestion challenge={challenge} index={index} showHeader />
        ))}
      </Accordion>
      }
      <EditCrazyCalculatorQuestionModal challenge={buildEmptyChallenge()}
                                        isModalOpen={isModalOpen}
                                        modalTitle="Create New Challenge"
                                        setIsModalOpen={setIsModalOpen}/>
    </div>
  );
};
