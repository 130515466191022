import React from "react";

export const divider =
  <div style={{ backgroundColor: "white", display: "flex", height: "54px", width: "1px" }}></div>;

export const getSessionItem = (title, value) =>
  <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
    <p style={{ fontSize: "18px", fontWeight: 700, lineHeight: "26px" }}>{title}</p>
    <div style={{ display: "flex", flexDirection: "row", fontSize: "14px", gap: "8px" }}>
      {value}
    </div>
  </div>;

export const getDate = ((startedAt) => {
  const date = new Date(startedAt);
  const dayOfWeek = date.toDateString().slice(0,3);
  const dateFormatted = (new Date(date - date.getTimezoneOffset() * 60000)).toISOString().slice(0,10);
  return dayOfWeek + " - " + dateFormatted;
});

export const getLength = ((startedAt, endedAt) => {
  if (!endedAt) {
    return 'Started at: ' + new Date(startedAt).toLocaleTimeString();
  } else {
    return new Date(startedAt).toLocaleTimeString() + ' - ' + new Date(endedAt).toLocaleTimeString();
  }
});

export const getDuration = ((startedAt, endedAt) => {
  if (!endedAt) {
    return 'IN PROGRESS';
  }
  const duration = new Date(endedAt) - new Date(startedAt);
  let seconds = Math.floor((duration / 1000) % 60);
  let minutes = Math.floor((duration / (1000 * 60)) % 60);
  let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;
  return hours + ":" + minutes + ":" + seconds;
});
