import "./sessionHistory.css";
import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import * as Sentry from "@sentry/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { request } from "hooks/useOkoApi/useOkoApi";
import {
  colors,
  constants,
  divider,
  generateRoutePath,
  getActivityName,
  getDate,
  getDuration,
  getLength,
  getSessionItem
} from "utils";
import { useAuthUserData } from "hooks/useAuthUserData/useAuthUserData";
import { useHistory } from "react-router-dom";

export const SessionHistory = () => {
  const { authUserData, loading } = useAuthUserData();
  const history = useHistory();
  const [sessionSummaries, setSessionSummaries] = useState([]);
  const [isSessionSummariesLoading, setIsSessionSummariesLoading] = useState(false);
  const [sessionSummariesPage, setSessionSummariesPage] = useState(1);
  const [isLastSessionSummariesPage, setIsLastSessionSummariesPage] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollOffset, setScrollOffset] = useState(0);
  const [error, setError] = useState();
  const getSessionData = async () => {
    if (!isLastSessionSummariesPage && !isSessionSummariesLoading) {
      try {
        setIsSessionSummariesLoading(true);
        const currentSessionsResponse = await request(
          `account/session_history?page_number=${sessionSummariesPage}&page_size=25`, {
            method: "GET",
          });
        const currentSessions = currentSessionsResponse.sessions;
        if (currentSessions?.length > 0) {
          setSessionSummaries([...sessionSummaries, ...currentSessions]);
          setSessionSummariesPage(sessionSummariesPage + 1);
        } else {
          setIsLastSessionSummariesPage(true);
        }
        setIsSessionSummariesLoading(false);
      } catch (e) {
        Sentry.captureException(e);
        setError(e?.detail || e?.message || "An unknown error occurred.");
        setIsSessionSummariesLoading(false);
      }
    }
  };

  useEffect(() => {
    if (sessionSummariesPage !== 1) {
      getSessionData();
    }
  }, [scrollOffset]);

  useEffect(() => {
    getSessionData();
  }, []);

  useEffect(() => {
    const elements = document.getElementsByClassName('accordion-item');
    let lastSessionItemOffsetTop = 0;
    let lastItemTarget;
    if (elements && elements.length > 0) {
      lastItemTarget = elements[elements.length - 1];
      lastSessionItemOffsetTop = lastItemTarget.offsetTop;
    }
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
      const offsetResult = window.innerHeight + e.target.documentElement.scrollTop - lastSessionItemOffsetTop - 100;
      if (!isSessionSummariesLoading && !isLastSessionSummariesPage && offsetResult > 0) {
        setScrollOffset(offsetResult);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  const getPrefixes = (prefixes) => {
    let result = prefixes.map((i) => i.standard_code);
    if (result.length > 0) {
      return result.join(", ");
    }
    return "N/A";
  };

  if (loading) return <></>;
  if (authUserData.isAnonymous) history.push(generateRoutePath(constants.EDUCATOR_LOGIN_ROUTE_NAME));
  return (
    <div className="session-history">
      <p style={{ fontSize: "12px", textAlign: "center", marginTop: "-22px", marginBottom: "10px" }}>
        Times in {new Date().toTimeString().slice(19).slice(0, -1)}
      </p>
      <Accordion>
        <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          {sessionSummaries && sessionSummaries.map((value, index) => (
            <Accordion.Item
              eventKey={index}
              key={index}
              style={{ backgroundColor: colors.neutral100, border: "none", borderRadius: "12px", padding: "16px 0" }}>
              <Accordion.Header
                className={ value.ended_at ? "" : "accordion-in-progress" }>
                <div style={{ display: "flex", flexDirection: "row", gap: "24px", padding: "0 24px" }}>
                  <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                    <p style={{ fontSize: "18px", fontWeight: 700, lineHeight: "26px" }}>{getActivityName(value.activity_type)}&nbsp;Activity</p>
                    <div style={{ display: "flex", flexDirection: "row", fontSize: "14px", gap: "8px" }}>
                      <p>{getDate(value.started_at)}</p>
                      <p>{getLength(value.started_at, value.ended_at)}</p>
                    </div>
                  </div>
                  {divider}
                  {getSessionItem("Duration", getDuration(value.started_at, value.ended_at))}
                  {divider}
                  {getSessionItem("Group Code", value.group_code.toUpperCase())}
                  {divider}
                  {getSessionItem("Core Standards", getPrefixes(value.standards))}
                  {divider}
                  {getSessionItem("Students", value.participant_names.join(", "))}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                  </div>
                  {value.summary_text ? (
                    <>
                      <p style={{ fontSize: "20px", margin: "24px 0" }}>Session Summary</p>
                      <div style={{ whiteSpace: "pre-wrap" }}>
                        {value.summary_text}
                      </div>
                    </>
                  ) : (
                    <div style={{ fontStyle: "italic", marginTop: "16px" }}>Session summary not yet available.</div>
                  )}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </div>
      </Accordion>
      {isSessionSummariesLoading && <div style={{ alignSelf: "center", position: "fixed", left: "50%", top: "50%" }}>
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          size="5x"
        />
      </div>}
    </div>
  )
};
