import { constants } from "./general";

export const buildEmptyChallenge = (mcMode = false) => {
  const challenge = {};
  challenge.difficulty = 1;
  challenge.standards = null;
  challenge.comments = null;
  challenge.status = "active";
  challenge.prompt = "";
  challenge.spoken_prompt = null;
  challenge.solution_index = null;
  challenge.answer_options = mcMode ? [] : null;
  challenge.randomize_options = false;
  challenge.prompt_image_url = null;
  challenge.render_type = "fraction_legacy";
  return challenge;
};

export const buildPreviewChallenge = (challenge) => {
  const previewChallenge = JSON.parse(JSON.stringify(challenge));
  const newAnswerOptions = [];
  previewChallenge?.answer_options?.forEach((option, index) => {
    newAnswerOptions[index] = {text: option, eligible: true};
  });

  if (newAnswerOptions.length) {
      previewChallenge.answer_options = newAnswerOptions;
  }
  
  return previewChallenge;
};

export const buttonIdsToStr = (buttonIds) => {
  if (buttonIds) {
    return buttonIds.map((i) => i.toString());
  }
  return [];
};

export const handleSaveChallengeError = (activity, errorDetails) => {
  if (Array.isArray(errorDetails)) {
    let relatedErrors = [];
    if (activity === constants.CONSENSUS_ENUM) {
      errorDetails.forEach((i) => {
        if (JSON.stringify(i).indexOf("MultipleChoice") > -1) {
          relatedErrors.push(i);
        }
      });
      return relatedErrors;
    } else if (activity === constants.CRAZY_CALCULATOR_ENUM) {
      errorDetails.forEach((i) => {
        if (JSON.stringify(i).indexOf("CrazyCalculator") > -1) {
          relatedErrors.push(i);
        }
      });
      return relatedErrors;
    }
  }
  return errorDetails;
};
