import "./gettingStartedModal.css";
import { Button, Modal } from "react-bootstrap";
import { colors } from "utils";
import Yay from "assets/yay.png";
import { useAuthorizedMeetingUserByEmail } from "hooks/useAuthorizedMeetingUsers/useAuthorizedMeetingUsers";

export const EducatorGettingStartedModal = ({ isOpen, setIsOpen }) => {
  const { authUser } = useAuthorizedMeetingUserByEmail();

  const handleClose = () => {
    window.localStorage.setItem("isDismissEducatorGettingStartedModal", "true");
    setIsOpen(false);
  };

  return (
    <Modal className="getting-started-modal" show={isOpen} onHide={handleClose}>
      <Modal.Header style={{
        border: "none",
        padding: 0 }} >
        <div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <img src={Yay} style={{ alignSelf: "center", maxWidth: "100%" }} />
            <strong style={{ fontSize: "36px", textAlign: "center" }}>Welcome to OKO!</strong>
          </div>
          <button className="getting-started-modal-close-button" onClick={handleClose}>
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="x-close">
                <path
                  id="Icon"
                  d="M15.5664 5L5.56641 15M5.56641 5L15.5664 15"
                  stroke="#879EB3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"/>
              </g>
            </svg>
          </button>
        </div>
      </Modal.Header>
      <div style={{
        alignItems: "center",
        display: "flex",
        height: "16px",
        justifyContent: "center" }}>
        <div style={{ border: "0.5px solid #D0D5DD", height: 0, width: "100%" }}>
        </div>
      </div>
      <div style={{ fontSize: "18px" }}>
        <p className="getting-started-item">Hello, {authUser.displayName}!</p>
        <p className="getting-started-item">Next Steps:</p>
        <p className="getting-started-item">&#8226;&nbsp;For each group code on your dashboard, select a grade level
          for the Let’s Talk activity. Any session started using this code will use questions based on the selected
          grade. You can select a different grade level for each class code to accommodate the differing levels of
          mastery in your classroom.</p>
        <p className="getting-started-item">&#8226;&nbsp;To share a group code, write it where students can see it or
          share it digitally by copying the link using the group code button on your dashboard.</p>
        <p className="getting-started-item">&#8226;&nbsp;Select a small group of 2 – 5 students, and sit them near
          each other, each with a laptop and headsets with microphones. (We recommend using the Chrome browser.)</p>
        <p className="getting-started-item">&#8226;&nbsp;To join a session, students can visit&nbsp;
          <a href={process.env.REACT_APP_OKOLABS_APP_URL}>{process.env.REACT_APP_OKOLABS_APP_URL}</a>
          &nbsp;and enter a group code OR they can click the link that you’ve shared digitally.</p>
        <p className="getting-started-item">At <a href={process.env.REACT_APP_OKOLABS_APP_URL}>
          {process.env.REACT_APP_OKOLABS_APP_URL}</a>, students can enter any of your group codes.</p>
      </div>
      <div style={{
        alignItems: "center",
        display: "flex",
        marginBottom: "16px",
        height: "16px",
        justifyContent: "center" }}>
        <div style={{ border: "0.5px solid #D0D5DD", height: 0, width: "100%" }}>
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px", justifyContent: "space-between" }}>
        <Button onClick={handleClose} style={{
          backgroundColor: colors.nightlyWoods500,
          border: "none",
          padding: "12px 24px",
          width: "100%" }} >
          Dismiss
        </Button>
      </div>
    </Modal>
  );
};
