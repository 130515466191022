import "../multipleChoiceActivity.css"
import * as Sentry from "@sentry/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSpinner } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { request } from "hooks/useOkoApi/useOkoApi";
import { useAuthUserData } from "hooks/useAuthUserData/useAuthUserData";
import { useStandards } from "hooks/useStandards";
import { buildSelectOptions} from "utils/standards";
import { buildPreviewChallenge } from "utils/challenge";
import { EditMultipleChoiceQuestionModal } from "./EditMultipleChoiceQuestionModal";
import { MultipleChoiceActivityRender } from "../MultipleChoiceActivityRender";
import { ConsensusVariantOptions, constants, generateRoutePath } from "utils";

export const MultipleChoiceQuestionRender = () => {
  const { authUserData, loading } = useAuthUserData();
  const history = useHistory();
  const standardsData = useStandards();
  const [questionsData, setQuestionsData] = useState([]);
  const [prefix, setPrefix] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [challengeToEdit, setChallengeToEdit] = useState();
  const [selectedFormat, setSelectedFormat] = useState("multiplechoice");

  const getQuestionsData = async (prefix, selectedFormat) => {
    try {
      setIsLoading(true);
      const questionsData = await request(
        `questions/fetch/${selectedFormat}?fetch_inactive=true${prefix ? `&standard_prefix=${prefix}` : ''}`, {
          method: "GET",
        });
      setQuestionsData(questionsData);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      Sentry.captureException(e);
    }
  };

  const handleOnStandardsChange = (target) => {
    const prefix = target.value;
    setPrefix(prefix);
    getQuestionsData(prefix, selectedFormat);
  };

  const handleOnFormatChange = (target) => {
    setSelectedFormat(target.value);
    getQuestionsData(prefix, target.value);
  };


  const getOptions = () => {
    const options = buildSelectOptions(standardsData);
    options.unshift({"value": null, label: "All available standards"});
    return options;
  };

  const onEdit = (challenge) => {
    setChallengeToEdit(challenge);
    setIsModalOpen(true);
  };

  const styles = {
    control: (css) => ({
      ...css,
      width: "800px",
    }),
  };

  const placeholderPrefix = prefix ? `for standard ${prefix}` : "";

  if (loading) return <></>;
  if (authUserData.isAnonymous) history.push(generateRoutePath(constants.ADMIN_LOGIN_ROUTE_NAME));
  return (
    <div>
      {isLoading && <div style={{ left: "50%", position: "absolute", top: "50%"}}>
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          size="5x"
        />
      </div>}
      <div style={{ alignItems: "center", display: "flex", flexDirection: "column", marginBottom: "35px" }}>
        <p>Fetched {questionsData.length} challenges {placeholderPrefix}</p>
        <br/>
        <Select isDisabled={isLoading} isSearchable placeholder="Select standard..." options={getOptions()} onChange={handleOnStandardsChange} styles={styles}/>
        <br/>
        <Select 
          isDisabled={isLoading} 
          value={ConsensusVariantOptions.find(option => option.value === selectedFormat)} // Ensure the selected option matches
          isSearchable 
          placeholder="Select format..." 
          options={ConsensusVariantOptions}
          onChange={handleOnFormatChange} 
          styles={styles}
          />
      </div>
      {!isLoading && <div style={{ alignItems: "center", display: "grid", justifyItems: "center" }}>
        {questionsData.map((challenge, index) => (
          <div style={{ display: "flex", marginBottom: "15px" }}>
            <MultipleChoiceActivityRender
              key={index}
              hideQuestion={false}
              timerTimeRemaining={null}
              selectedIndex={null}
              challenge={buildPreviewChallenge(challenge)}
              disableOptions={null}
              onMCAnswer={() => {}}
              onORAnswer={() => {}}
              isMathJaxSet={false} // Needed to prevent race condition on MathJax typesetting.
            />
            <button style={{
              borderRadius: "100px",
              borderWidth: "1px",
              marginLeft: "18px",
              width: "75px",
              height: "75px",
              alignSelf: "center"
            }} onClick={() => onEdit(challenge)}>
              <FontAwesomeIcon icon={faEdit} />
              &nbsp;Edit
            </button>
            <br/>
          </div>
        ))}
      </div>
      }
      {challengeToEdit && <EditMultipleChoiceQuestionModal challenge={challengeToEdit}
                                                           isModalOpen={isModalOpen}
                                                           modalTitle="Edit Challenge"
                                                           setIsModalOpen={setIsModalOpen}/>
      }
    </div>
  );
};
